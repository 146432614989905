import { Link } from 'gatsby';
import React from 'react';

const Menu = ({ menuLinks }) => (
  <nav>
    <ul>
      {menuLinks.map(link => (
        <li key={link.name}>
          <Link to={link.link}>{link.name}</Link>
        </li>
      ))}
    </ul>
  </nav>
);

export default Menu;
